/* eslint-disable no-unused-vars */
import { createStore } from 'vuex'
import axios from 'axios'
import * as Cookies from 'js-cookie'
import router from '@/router'

export default createStore({
	state: {
		baseurl: 'https://referansapi.tusdata.com/api/',
		loggedin: false,
		usertype: null,
		username: '',
		userid: null,
		usercatid: null,
		selectedcatid:null,
		userauth: [],
		userrole: null,
		isScrolled: false,
		scrollY: 0,
		workFolder: '',
		workYear: '',
		workMonth: '',
		choices: ['A', 'B', 'C', 'D', 'E'],
		tustotalq: 200,
		dustotalq: 120,
		tustemelq: 100,
		tusklinikq: 100,
		dustemelq: 40,
		dusklinikq: 80,
		supers: [1, 5, 6, 7],
	},
	mutations: {},
	actions: {
		getDatas: async ({ state, dispatch }, data) => {
			var header = {
				'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
			}
			if (data.pos !== 'login') {
				let j = await dispatch('authControl')
				if (!j) return
				else {
					header.Authorization = 'Bearer ' + j.t
					header.DType = state.usertype.toString()
				}
			}

			var urlparam = '?'
			if (data.params) {
				Object.keys(data.params).forEach((key, i) => {
					urlparam +=
						key +
						'=' +
						data.params[key] +
						(i === Object.keys(data.params).length - 1 ? '' : '&')
				})
			}
			const res = await axios
				.get(state.baseurl + data.api + urlparam, {
					headers: header,
				})
				.then(res => {
					//state.errorapi = false
					return res.data
				})
				.catch(error => {
					state.errorapi = true
					if (error.response.status === 401) {
						router.push('/giris')
						return
					}
					//console.log("geterr",error.response)
				})
			return res
		},
		postDatas: async ({ dispatch, state }, data) => {
			var opts = {
				headers: {},
			}
			if (data.pos !== 'login') {
				var j = await dispatch('authControl')
				if (!j) return
				else {
					opts.headers.Authorization = 'Bearer ' + j.t
				}
			}
			opts.headers.DType = state.usertype.toString()

			let sdata = data.data || {}
			if (data.headers !== undefined) opts.headers = data.headers
			const res = await axios
				.post(state.baseurl + data.api, sdata, opts)
				.then(res => {
					//state.errorapi = false
					return res.data
				})
				.catch(error => {
					state.errorapi = true
					//console.log("err",error)
					if (error.response.status === 401) {
						router.push('/giris')
						return
					}
				})
			return res
		},
		authControl: async ({ state }) => {
			let uc = Cookies.get('tdutype')

			if (uc) {
				let t = JSON.parse(uc)
				state.usertype = t.ut
				let c = ''
				if (state.usertype === 0) c = Cookies.get('tdataref')
				if (state.usertype === 1) c = Cookies.get('ddataref')
				if (state.usertype === 2) c = Cookies.get('yddataref')
				if (c) {
					let j = JSON.parse(c)
					if (!j.t) {
						if (state.usertype === 0) Cookies.remove('tdataref')
						if (state.usertype === 1) Cookies.remove('ddataref')
						if (state.usertype === 2) Cookies.remove('yddataref')
						router.push('/giris')
					} else {
						state.userid = j.uid
						state.userauth = j.aut
						state.username = j.n
						state.userrole = j.r
						state.usercatid = j.cid
						return j
					}
				} else {
					router.push('/giris')
				}
			} else {
				router.push('/giris')
			}
		},
		getWorkFolder: async ({ state, dispatch }) => {
			let list = await dispatch('getDatas', {
				api: 'settings/getmonthyear',
			})
			state.workFolder = list.data.year + list.data.month
			state.workYear = list.data.year
			state.workMonth = list.data.month
			let c = ''
			if (state.usertype === 0) c = Cookies.get('tdataref')
			if (state.usertype === 1) c = Cookies.get('ddataref')
			if (state.usertype === 2) c = Cookies.get('yddataref')
			if (c) {
				let j = JSON.parse(c)
				j.wy = list.data.year
				j.wm = list.data.month
				if (state.usertype === 0) Cookies.set('tdataref', JSON.stringify(j))
				if (state.usertype === 1) Cookies.set('ddataref', JSON.stringify(j))
				if (state.usertype === 2) Cookies.set('yddataref', JSON.stringify(j))
			} else {
				router.push('/giris')
			}
		},
	},
	modules: {},
})
